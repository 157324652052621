<template>
  <div class="p-col-12">
    <Panel header="Gerenciamento de Estabelecimentos">
      <Toolbar>
        <template #start>
          <Button
            label="Novo"
            icon="pi pi-plus"
            class="mr-2"
            @click="showCreate"
          />
        </template>
        <template #end>
          <div
            class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
          >
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>
      </Toolbar>
      <DataTable
        ref="dt"
        dataKey="id"
        class="p-datatable-sm"
        :value="estabelecimentos"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[10, 20, 50]"
        :filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} locais."
        :loading="loading"
      >
        <template #empty>
          <div class="p-text-center">Nenhum resultado encontrado...</div>
        </template>
        <Column field="nome" header="Nome da Instituição" :sortable="true">
          <template #body="slotProps">
            <div v-if="slotProps.data.nome">
              {{ slotProps.data.nome }}
            </div>
            <div v-else style="color: red"><b>PENDENTE</b></div>
          </template>
        </Column>
        <Column header="CNES" field="cnes" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.cnes }}
          </template>
        </Column>
        <Column header="CNPJ" field="cnpj" :sortable="true">
          <template #body="slotProps">
            <div v-if="slotProps.data.cnpj">
              {{ slotProps.data.cnpj }}
            </div>
            <div v-else style="color: red"><b>PENDENTE</b></div>
          </template>
        </Column>
        <Column header="Contato" field="contato" :sortable="true">
          <template #body="slotProps">
            <div v-if="slotProps.data.contato">
              {{ slotProps.data.contato }}
            </div>
            <div v-else style="color: red"><b>PENDENTE</b></div>
          </template>
        </Column>
        <Column header="Gestão" field="gestao" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.gestao.descricao }}
          </template>
        </Column>
        <Column header="Natureza Jurídica" field="natureza" :sortable="true">
          <template #body="slotProps">
            <div v-if="slotProps.data.natureza.descricao !== 'PENDENTE'">
              {{ slotProps.data.natureza.descricao }}
            </div>
            <div v-else style="color: red"><b>PENDENTE</b></div>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="showUpdate(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA EDITAR'"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="showRemove(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA EXCLUIR'"
            />
          </template>
        </Column>
      </DataTable>
    </Panel>
    <ConfirmDialog></ConfirmDialog>
  </div>
  <dialog-form
    :objSelected="estabelecimentoSelected"
    :headerDialog="'Formulário de Estabelecimento'"
    :closeDialog="true"
    @findAll="findAll"
  />
</template>

<script>
import { FilterMatchMode } from "primevue/api";

//Models
import Estabelecimento from "../../models/estabelecimento";

//Services
import EstabelecimentoService from "../../service/estabelecimento/estabelecimento_service";

//Components
import DialogForm from "./components/dialog-form.vue";

export default {
  components: {
    DialogForm,
  },
  data() {
    return {
      estabelecimentoService: new EstabelecimentoService(),
      estabelecimento: new Estabelecimento(),
      loading: false,
      estabelecimentos: [],
      estabelecimentoSelected: null,
      selectAll: false,
      filters: {},
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.loading = true;
    this.findAll();
  },
  methods: {
    showCreate() {
      this.estabelecimento = new Estabelecimento();
      this.$store.state.views.estabelecimento.dialogForm = true;
    },
    showUpdate(estabelecimento) {
      this.estabelecimentoSelected = { ...estabelecimento };
      this.$store.state.views.estabelecimento.dialogForm = true;
    },
    showRemove(data) {
      this.$confirm.require({
        message: "Deseja Excluir esse registro?",
        header: "Deseja deletar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.estabelecimentoService
            .delete(data.id)
            .then((data) => {
              this.$msgSuccess(data);
              this.findAll();
            })
            .catch((error) => {
              this.$msgErro(error);
              //console.error(error);
            });
        },
      });
    },
    findAll() {
      this.loading = true;
      this.estabelecimentoService.findAll().then((data) => {
        this.estabelecimentos = data;
        this.loading = false;
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../App.scss");
</style>
